.wrapper .content-wrapper {
    min-height: calc(100vh - 58px - 186px);
}
.layout-fixed .wrapper .sidebar {
    height: calc(100vh - 24px - 3.5rem - 1px);
    overflow: hidden;
}

.btn-teal.disabled, .btn-teal:disabled {
    background-color: #20c997;
    border-color: #20c997;
}
.btn-teal {
    color: #fff;
    background-color: #20c997;
    border-color: #20c997;
    box-shadow: none;
}
.btn-fuchsia.disabled, .btn-fuchsia:disabled {
    background-color: #f012be;
    border-color: #f012be;
}
.btn-fuchsia {
    color: #fff;
    background-color: #f012be;
    border-color: #f012be;
    box-shadow: none;
}
[class^=btn-].disabled {
    opacity: .65;
}

.dataTables_length, .dataTables_info, .dt-buttons {
    padding-left: 12px;
}
.dataTables_filter, .dataTables_paginate {
    padding-right: 12px;
}